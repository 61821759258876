<template>
    <div class="bg-gray-100 py-10 px-4 sm:px-6">
      <!-- Header Section -->
      <h1 class="text-3xl sm:text-4xl font-bold text-center text-black-500 mb-6">Gallery</h1>
  
      <!-- Divider -->
      <div class="w-full h-1 bg-red-500 mb-8"></div>
  
      <!-- Gallery Grid -->
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 container mx-auto">
        <div class="overflow-hidden rounded-lg shadow-lg transition-transform transform hover:scale-105 bg-white">
          <img 
            src="../assets/b1.jpg" 
            alt="Bhujia" 
            class="w-full h-64 object-cover">
        </div>
        <div class="overflow-hidden rounded-lg shadow-lg transition-transform transform hover:scale-105 bg-white">
          <img 
            src="../assets/b2.jpg" 
            alt="Chira" 
            class="w-full h-64 object-cover">
        </div>
        <div class="overflow-hidden rounded-lg shadow-lg transition-transform transform hover:scale-105 bg-white">
          <img 
            src="../assets/b3.jpeg" 
            alt="Nimkee" 
            class="w-full h-64 object-cover">
        </div>
        <div class="overflow-hidden rounded-lg shadow-lg transition-transform transform hover:scale-105 bg-white">
          <img 
            src="../assets/b4.webp" 
            alt="Ladoo" 
            class="w-full h-64 object-cover">
        </div>
        <div class="overflow-hidden rounded-lg shadow-lg transition-transform transform hover:scale-105 bg-white">
          <img 
            src="../assets/b5.webp" 
            alt="Namkeen" 
            class="w-full h-64 object-cover">
        </div>
        <div class="overflow-hidden rounded-lg shadow-lg transition-transform transform hover:scale-105 bg-white">
          <img 
            src="../assets/b6.jpg" 
            alt="Halwa" 
            class="w-full h-64 object-cover">
        </div>
        <div class="overflow-hidden rounded-lg shadow-lg transition-transform transform hover:scale-105 bg-white">
          <img 
            src="../assets/b7.webp" 
            alt="Halwa" 
            class="w-full h-64 object-cover">
        </div>
        <div class="overflow-hidden rounded-lg shadow-lg transition-transform transform hover:scale-105 bg-white">
          <img 
            src="../assets/b8.jpg" 
            alt="Halwa" 
            class="w-full h-64 object-cover">
        </div>
        <div class="overflow-hidden rounded-lg shadow-lg transition-transform transform hover:scale-105 bg-white">
          <img 
            src="../assets/b9.jpeg" 
            alt="Halwa" 
            class="w-full h-64 object-cover">
        </div>
        <div class="overflow-hidden rounded-lg shadow-lg transition-transform transform hover:scale-105 bg-white">
          <img 
            src="../assets/b10.jpeg" 
            alt="Halwa" 
            class="w-full h-64 object-cover">
        </div>
        <div class="overflow-hidden rounded-lg shadow-lg transition-transform transform hover:scale-105 bg-white">
          <img 
            src="../assets/b11.jpg" 
            alt="Halwa" 
            class="w-full h-64 object-cover">
        </div>
        <div class="overflow-hidden rounded-lg shadow-lg transition-transform transform hover:scale-105 bg-white">
          <img 
            src="../assets/b12.jpg" 
            alt="Halwa" 
            class="w-full h-64 object-cover">
        </div>
      </div>
    </div>

    <footer class="bg-gray-500 text-white py-6">
      <div class="container mx-auto px-4 flex flex-col md:flex-row justify-between items-start md:items-center">
        <!-- Left Section: Address and Buttons -->
        <div class="md:w-1/2 mb-6 md:mb-0">
          <address class="text-lg font-bold mb-4">
            Vill.+P.o.- Simulia choice, P.s.- Mongolkote, District- Purba Bardhaman, West Bengal, India.

          </address>
          <div class="flex flex-wrap space-x-0 md:space-x-4 space-y-2 md:space-y-0">
            <a 
              href="mailto:keshabbiswas.83@gmail.com " 
              class="bg-white text-red-500 px-4 py-2 rounded hover:bg-red-100 hover:text-red-500 transition w-full md:w-auto text-center">
              Mail Us
            </a>
            <a 
              href="tel:9333679474" 
              class="bg-white text-red-500 px-4 py-2 rounded hover:bg-red-100 hover:text-red-500 transition w-full md:w-auto text-center">
              Call Us
            </a>
            <a 
              href="tel:6295066185" 
              class="bg-white text-red-500 px-4 py-2 rounded hover:bg-red-100 hover:text-red-500 transition w-full md:w-auto text-center">
              Call Us
            </a>
          </div>
        </div>
        
        <!-- Right Section: Embedded Map -->
        <div class="md:w-1/2 w-full">
          <iframe 
            class="w-full h-48 md:h-32 rounded-lg shadow-lg"
            src="https://www.google.com/maps/embed/v1/place?q=Vill.-+Sahebganj,+P.O.-+Ballavpur,+P.S.-+Raniganj,+District-+Paschim+Bardhaman,+Pin-+713323,+West+Bengal,+India.+&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" 
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade">
          </iframe>
        </div>
      </div>
  
      <!-- Bottom Section -->
      <div class="mt-6 text-center px-4">
        <p>&copy; 2024 Bureau Global CertificationIcon. All rights reserved.</p>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'GalleryView'
  }
  </script>
  
  <style scoped>
  /* Smooth hover animations */
  .hover\:scale-105 {
    transition: transform 0.3s ease-in-out;
  }
  </style>
  