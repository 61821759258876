<template>
    <div>
      <!-- Welcome Section -->
      <div class="w-full h-1 bg-red-500 mt-5 mb-5"></div>
      <div class="bg-gray-100 py-10 px-4 sm:px-8">
        <h1 class="text-3xl sm:text-4xl font-bold text-center text-black mb-500">Welcome to Our Industry</h1>
        <div class="w-full h-1 bg-red-500 mt-5 mb-5"></div>
        
        <img alt="banner" src="../assets/banner.png" class="w-full mt-5 rounded-lg shadow-md hover:shadow-lg hover:scale-105 transition-transform transform duration-300">
      </div>
  
      <!-- Divider -->
      <div class="w-full h-1 bg-black-500 mt-5 mb-5"></div>
  
      <!-- Top Products Section -->
      <div class="py-10 px-4 sm:px-8">
        <h1 class="text-3xl sm:text-4xl font-bold text-center text-black mb-500">Top Products</h1>
        <div class="mt-10">
          <swiper-container
            class="swiper-container flex flex-col items-center justify-center"
            :slides-per-view="1"
            :space-between="20"
            loop="true"
            navigation="true"
            pagination="true"
            :breakpoints="{
              640: { slidesPerView: 2, spaceBetween: 20 },
              1024: { slidesPerView: 3, spaceBetween: 30 }
            }"
          >
            <!-- Slide 1 -->
            <swiper-slide class="items-center justify-center p-4 text-center bg-white rounded-lg shadow-lg hover:bg-black-100">
              <img alt="Product 2" src="../assets/b10.jpeg" class="w-full h-60 object-cover rounded-lg shadow-lg">
              <h2 class="text-xl sm:text-2xl font-bold mt-4 text-black-500"></h2>
              
            </swiper-slide>
  
            <!-- Slide 2 -->
            <swiper-slide class="flex flex-col items-center justify-center p-4 text-center bg-white rounded-lg shadow-lg hover:bg-red-100">
              <img alt="Product 3" src="../assets/b1.jpg" class="w-full h-60 object-cover rounded-lg shadow-lg">
              <h2 class="text-xl sm:text-2xl font-bold mt-4 text-black-500"></h2>
            </swiper-slide>
  
            <!-- Slide 3 -->
            
  
            <!-- Slide 4 -->
            <swiper-slide class="flex flex-col items-center justify-center p-4 text-center bg-white rounded-lg shadow-lg hover:bg-red-100">
              <img alt="Product 5" src="../assets/b4.webp" class="w-full h-60 object-cover rounded-lg shadow-lg">
              <h2 class="text-xl sm:text-2xl font-bold mt-4 text-black-500"></h2>
              
            </swiper-slide>
  
            <!-- Slide 5 -->
            <swiper-slide class="flex flex-col items-center justify-center p-4 text-center bg-white rounded-lg shadow-lg hover:bg-red-100">
              <img alt="Product 6" src="../assets/b6.jpg" class="w-full h-60 object-cover rounded-lg shadow-lg">
              <h2 class="text-xl sm:text-2xl font-bold mt-4 text-black-500"></h2>
            </swiper-slide>
          </swiper-container>
        </div>
      </div>
    </div>

    <div class="bg-gray-100 py-10 px-4 sm:px-6">
      <!-- Header Section -->
      <h1 class="text-3xl sm:text-4xl font-bold text-center text-black-500 mb-6">About Us</h1>
      <div class="container mx-auto">
        <!-- Content Section -->
        <div class="flex flex-col md:flex-row items-center bg-white rounded-lg shadow-lg overflow-hidden transition-transform transform hover:scale-105">
          <!-- Image Section -->
          <div class="w-full md:w-1/2 h-auto">
            <img 
              src="../assets/be1.png" 
              alt="About Us" 
              class="w-full h-auto object-contain md:rounded-l-none">
          </div>
          <!-- Text Section -->
          <div class="w-full md:w-1/2 p-4 sm:p-6">
            <h2 class="text-2xl sm:text-3xl font-semibold text-black-500">Welcome to Biswas Flyers Brickfield</h2>
            <p class="mt-4 text-gray-700 leading-relaxed">
              We Biswas Flyers Brickfield, are a dynamic manufacturer of ash bricks or fly ash bricks in the domain. From the very inception year we are committed to innovativeness and dynamic nature of the products. The good start of our organization took place with the marvelous hands of keshab Biswas who is a dedicated skilled expert in the field. Along with him the extraordinary collaborative team helps us to put one step further in the way of success. 
            </p>
            <p class="mt-4 text-gray-700 leading-relaxed">
              From sourcing the best ingredients to maintaining high standards of hygiene and packaging, Ruchi Snacks 
              Industries ensures that every product meets your expectations. Our team works tirelessly to uphold our 
              legacy of quality and trust, making us a household name in the snack industry.
            </p>
          </div>
        </div>
      </div>
    </div>
  
    <footer class="bg-gray-500 text-white py-6">
      <div class="container mx-auto px-4 flex flex-col md:flex-row justify-between items-start md:items-center">
        <!-- Left Section: Address and Buttons -->
        <div class="md:w-1/2 mb-6 md:mb-0">
          <address class="text-lg font-bold mb-4">
            Vill.+P.o.- Simulia choice, P.s.- Mongolkote, District- Purba Bardhaman, West Bengal, India.

          </address>
          <div class="flex flex-wrap space-x-0 md:space-x-4 space-y-2 md:space-y-0">
            <a 
              href="mailto:keshabbiswas.83@gmail.com " 
              class="bg-white text-red-500 px-4 py-2 rounded hover:bg-red-100 hover:text-red-500 transition w-full md:w-auto text-center">
              Mail Us
            </a>
            <a 
              href="tel:9333679474" 
              class="bg-white text-red-500 px-4 py-2 rounded hover:bg-red-100 hover:text-red-500 transition w-full md:w-auto text-center">
              Call Us
            </a>
            <a 
              href="tel:6295066185" 
              class="bg-white text-red-500 px-4 py-2 rounded hover:bg-red-100 hover:text-red-500 transition w-full md:w-auto text-center">
              Call Us
            </a>
          </div>
        </div>
        
        <!-- Right Section: Embedded Map -->
        <div class="md:w-1/2 w-full">
          <iframe 
            class="w-full h-48 md:h-32 rounded-lg shadow-lg"
            src="https://www.google.com/maps/embed/v1/place?q=Vill.-+Sahebganj,+P.O.-+Ballavpur,+P.S.-+Raniganj,+District-+Paschim+Bardhaman,+Pin-+713323,+West+Bengal,+India.+&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" 
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade">
          </iframe>
        </div>
      </div>
  
      <!-- Bottom Section -->
      <div class="mt-6 text-center px-4">
        <p>&copy; 2024 Bureau Global CertificationIcon. All rights reserved.</p>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'HomeView',
  };
  import 'swiper/swiper-bundle.css';
  import { register } from 'swiper/element/bundle';
  register();
  </script>
  
  <style>
  /* Swiper Styles */
  .swiper-container {
    width: 100%;
    padding: 20px 0;
  }
  
  .swiper-slide img {
    transition: transform 0.3s ease;
  }
  
  .swiper-slide img:hover {
    transform: scale(1.05);
  }
  </style>