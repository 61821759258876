<template>
  <nav class="bg-gray-500 text-white p-4 flex gap-4 justify-left rounded border">
    <router-link to="/" class="font-bold text-2xl border p-2 rounded border-black hover:bg-white hover:text-black">Home</router-link> 
    <router-link to="/about" class="font-bold text-2xl border p-2 rounded border-black hover:bg-white hover:text-black">About</router-link>
    <router-link to="/gallery" class="font-bold text-2xl border p-2 rounded border-black hover:bg-white hover:text-black">Gallery</router-link>
  </nav>
  <router-view/>
</template>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
