<template>
    <div class="bg-gray-100 py-10 px-4 sm:px-6">
      <!-- Header Section -->
      <h1 class="text-3xl sm:text-4xl font-bold text-center text-black-500 mb-6">About Us</h1>
      <div class="container mx-auto">
        <!-- Content Section -->
        <div class="flex flex-col md:flex-row items-center bg-white rounded-lg shadow-lg overflow-hidden transition-transform transform hover:scale-105">
          <!-- Image Section -->
          <div class="w-full md:w-1/2 h-auto">
            <img 
              src="../assets/be1.png" 
              alt="About Us" 
              class="w-full h-auto object-contain md:rounded-l-none">
          </div>
          <!-- Text Section -->
          <div class="w-full md:w-1/2 p-4 sm:p-6">
            <h2 class="text-2xl sm:text-3xl font-semibold text-black-500">Welcome to  Biswas Flyers Brickfield</h2>
            <p class="mt-4 text-gray-700 leading-relaxed">
              Introduction:
Fly ash bricks have emerged as a widely preferred alternative to traditional clay bricks in modern construction. Crafted from a blend of fly ash, coal combustion residue, cement, sand, and water, these bricks are more eco-friendly and sustainable compared to conventional bricks. At Biswas Flyers Brickfield, we take pride in being a prominent contributor to the construction industry by offering premium-quality fly ash bricks. Our diverse range of products is designed to minimize the environmental footprint of waste materials. Engineered with outstanding strength and durability, our bricks enhance the quality and sustainability of building projects.
            </p>
            
          </div>
        </div>
      </div>
      <div class="container mx-auto mt-10">
        <!-- Content Section -->
        <div class="flex flex-col md:flex-row items-center bg-white rounded-lg shadow-lg overflow-hidden transition-transform transform hover:scale-105">
          <!-- Image Section -->
          <div class="w-full md:w-1/2 h-auto">
            <img 
              src="../assets/be2.png" 
              alt="About Us" 
              class="w-full h-auto object-contain md:rounded-l-none">
          </div>
          <!-- Text Section -->
          <div class="w-full md:w-1/2 p-4 sm:p-6">
            <h2 class="text-2xl sm:text-3xl font-semibold text-red-500"></h2>
            <p class="mt-4 text-gray-700 leading-relaxed">
              Infrastructure:
Our infrastructure forms the backbone of our success, spanning a vast area equipped with all the essential components for seamless production. We utilize state-of-the-art machinery, including mixers, brick pressers, kilns, and cutters, ensuring efficiency and quality in every step of the process. Ample storage facilities are available for raw materials, while our dedicated team meticulously oversees every phase, from material inspection and production to distribution.

To streamline operations, we have a designated packaging area and a well-organized warehouse, facilitating easy access to various brick sizes and accurate inventory management. Additionally, our office space supports crucial business functions such as sales, customer interactions, and administrative tasks, ensuring smooth and efficient operations across all departments.

            </p>
            <p class="mt-4 text-gray-700 leading-relaxed">
                 


            </p>
          </div>
        </div>
      </div>


      <div class="container mx-auto mt-10">
        <!-- Content Section -->
        <div class="flex flex-col md:flex-row items-center bg-white rounded-lg shadow-lg overflow-hidden transition-transform transform hover:scale-105">
          <!-- Image Section -->
          <!-- Text Section -->
          <div class="w-full md:w-1/2 p-4 sm:p-6">
            <h2 class="text-2xl sm:text-3xl font-semibold text-red-500"></h2>
            <p class="mt-4 text-gray-700 leading-relaxed">
              Our mission is to enhance the construction industry by providing high-quality, durable, and impeccably designed products. We strive to build a reputation as a reliable partner in construction projects, fostering trust and excellence.
            </p>
            <p class="mt-4 text-gray-700 leading-relaxed">
              We aspire to establish our fly ash bricks as a leading brand in the construction industry, seamlessly blending innovation with environmental harmony. Our vision is to shape a sustainable, energy-efficient future while creating structures that inspire vibrancy and resilience.
         
            </p>
             </div>
          <div class="w-full md:w-1/2 h-auto">
            <img 
              src="../assets/be3.png" 
              alt="About Us" 
              class="w-full h-auto object-contain md:rounded-l-none">
          </div>
        </div>
      </div>
    </div>

    <div class="container mx-auto">
        <!-- Content Section -->
        <div class="flex flex-col md:flex-row items-center bg-white rounded-lg shadow-lg overflow-hidden transition-transform transform hover:scale-105">
          <!-- Image Section -->
          <div class="w-full md:w-1/2 h-auto">
            <img 
              src="../assets/be4.png" 
              alt="About Us" 
              class="w-full h-auto object-contain md:rounded-l-none">
          </div>
          <!-- Text Section -->
          <div class="w-full md:w-1/2 p-4 sm:p-6">
            <h2 class="text-2xl sm:text-3xl font-semibold text-red-500"></h2>
            <p class="mt-4 text-gray-700 leading-relaxed">
              Since our inception, we have been dedicated to delivering exceptional quality as a growing brand in the construction industry. Here are the key reasons to choose us:

    Innovative Solutions: We bring creativity and modern approaches to our products.
    Sustainability: Our processes are eco-friendly and designed to reduce environmental impact.
    Customer-Centric Approach: We prioritize the needs and satisfaction of our customers.
    Affordable Pricing: High-quality products at competitive rates.
    Timely Service: Ensuring prompt and reliable delivery every time.
    Durability and Quality Assurance: Products built to last with a commitment to excellence.
            </p>
            
          </div>
        </div>
      </div>
     
    <footer class="bg-gray-500 text-white py-6">
      <div class="container mx-auto px-4 flex flex-col md:flex-row justify-between items-start md:items-center">
        <!-- Left Section: Address and Buttons -->
        <div class="md:w-1/2 mb-6 md:mb-0">
          <address class="text-lg font-bold mb-4">
            Vill.+P.o.- Simulia choice, P.s.- Mongolkote, District- Purba Bardhaman, West Bengal, India.

          </address>
          <div class="flex flex-wrap space-x-0 md:space-x-4 space-y-2 md:space-y-0">
            <a 
              href="mailto:keshabbiswas.83@gmail.com " 
              class="bg-white text-red-500 px-4 py-2 rounded hover:bg-red-100 hover:text-red-500 transition w-full md:w-auto text-center">
              Mail Us
            </a>
            <a 
              href="tel:9333679474" 
              class="bg-white text-red-500 px-4 py-2 rounded hover:bg-red-100 hover:text-red-500 transition w-full md:w-auto text-center">
              Call Us
            </a>
            <a 
              href="tel:6295066185" 
              class="bg-white text-red-500 px-4 py-2 rounded hover:bg-red-100 hover:text-red-500 transition w-full md:w-auto text-center">
              Call Us
            </a>
          </div>
        </div>
        
        <!-- Right Section: Embedded Map -->
        <div class="md:w-1/2 w-full">
          <iframe 
            class="w-full h-48 md:h-32 rounded-lg shadow-lg"
            src="https://www.google.com/maps/embed/v1/place?q=Vill.-+Sahebganj,+P.O.-+Ballavpur,+P.S.-+Raniganj,+District-+Paschim+Bardhaman,+Pin-+713323,+West+Bengal,+India.+&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" 
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade">
          </iframe>
        </div>
      </div>
  
      <!-- Bottom Section -->
      <div class="mt-6 text-center px-4">
        <p>&copy; 2024 Bureau Global CertificationIcon. All rights reserved.</p>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: "AboutView",
  };
  </script>
  
  <style scoped>
  /* Smooth hover animations */
  .hover\:scale-105 {
    transition: transform 0.3s ease-in-out;
  }
  </style>
  